import React, { Component } from 'react';
import { Col, message, Popover, Row, Spin } from 'antd';
import QRCode from 'qrcode.react';

import server from '../../utils/server';
import apiChengshi from '../../configs/api-chengshi';

import AuthUtil from '../../utils/AuthUtil';
import DownloadGoogle from '../../components/DownloadGoogle';

require('./login.less');

const logo = require('../../images/chengshi/logo_small.png');
const logoYouche = require('../../images/chengshi/logo_large.png');
const topLogo = require('../../images/login/top_logo.png');

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginExpire: false,
      code_id: '',
      btn_value: '获取验证码',
      is_disabled: false,
      opacity: 1,
      loginId: '',
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.getVerifyCode = this.getVerifyCode.bind(this);
  }

  componentDidMount() {
    if (AuthUtil.isLogin() && AuthUtil.isYoucheRegionAdmin()) {
      location.href = '/';
    } else {
      this.getLoginId();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interVal);
  }

  handleRefresh() {
    this.getLoginId();
    clearInterval(this.interVal);
  }

  getVerifyCode() {
    const phone = this.formRef.getFieldValue('phone');
    if (!phone) {
      message.error('请输入电话号码');
      return false;
    }

    let num = 10;
    this.setState({ is_disabled: 'disable', opacity: 0.5 });

    let btn_value = `${num}s`;
    this.setState({ btn_value });

    let time = setInterval(() => {
      num--;
      btn_value = `${num}s`;
      this.setState({ btn_value });

      if (num === 0) {
        this.setState({
          is_disabled: false,
          opacity: 1,
          btn_value: '获取验证码',
        });
        clearInterval(time);
        time = undefined;
      }
    }, 1000);

    server.post(apiChengshi.system.getVerifyCode(), {
      phone, permission: 'no-login',
    }, (data) => {
      message.info('验证码已发送', 3);
      const { sms } = data.res;
      if (sms && Object.keys(sms).length > 0) {
        this.setState({ code_id: sms._id });
      } else {
        message.error('验证码获取失败');
      }
    });
  }

  getLoginId() {
    server.get(apiChengshi.system.genLoginIId(), (data) => {
      this.setState({ loginId: data.res.login_id, isLoginExpire: false }, () => {
        try {
          this.interVal = setInterval(() => {
            if (this.interVal) {
              this.getLoginById(this.state.loginId);
            }
          }, 2000);
        } catch (e) {
          if (this.interVal) {
            clearInterval(this.interVal);
          }
          this.interVal = null;
          this.setState({
            isLoginExpire: false,
          });
        }

        const time = setTimeout(() => {
          this.setState({
            isLoginExpire: true,
          });
          clearTimeout(time);
          clearInterval(this.interVal);
        }, 1000 * 60);
      });
    });
  }

  getLoginById(loginId) {
    if (loginId) {
      server.get(apiChengshi.system.loginById(loginId), (data) => {
        window.accessToken = `Bearer ${data.res.access_token}`;
        localStorage.setItem('refressToken', `${data.res.refress_token}`);
        this.getUserInfo();
        clearInterval(this.interVal);
      }, () => {

      });
    }
  }

  getUserInfo() {
    // 1. 获取登录用户信息
    server.get(apiChengshi.system.userInfo(), (data) => {
      const { user } = data.res;
      if (user && Object.keys(user).length > 0) {
        user.uid = user._id;
        localStorage.setItem('USER_SESSION', window.btoa(window.encodeURIComponent(JSON.stringify(user))));
        const date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes();
        localStorage.setItem('last_update', ((hour * 60) + minute).toString());
        localStorage.setItem('last_update_day', new Date().getDate().toString());
        message.success('登录成功');
        setTimeout(() => {
          location.href = '/';
        }, 500);
      } else {
        message.error('获取用户信息失败, 请重新登录');
      }
    });
  }

  render() {
    const { isLoginExpire, loginId } = this.state;

    return (
      <div>
        <div className="content">
          <header>
            <div className="logo">
              <img src={logo} alt="水稻优车" />
            </div>
            <div className="phone">
              <span className="mr10">寻求帮助</span>
              <span>400-900-9556</span>
            </div>
            <div className="top-logo">
              <span className="mr10">系统支持</span>
              <img src={topLogo} alt="" />
            </div>
          </header>

          <section style={{ height: document.body.clientHeight - 165, minHeight: '550px' }}>
            <div className="section-content">
              <div className="word">
                <div className="name">
                  <img src={logoYouche} style={{ width: '280px', height: '50px' }} />
                </div>
                <div className="slogan-one mt15">配件商城+金融产品 一站式服务平台</div>
              </div>

              <div className="sign-in" style={{ marginTop: 160, marginLeft: 93, width: 376 }}>
                <div className="accountLogin">
                  <p>账号登录</p>
                </div>
                <div className="flex-center">
                  <div className="flex-center mt20 mb20" style={{ width: 270, color: '#666666' }}>
                    <span>
                      请使用
                      <Popover
                        placement="bottom"
                        title={null}
                        content={(
                          <div className="canvas no-print qr-popover-home">
                            <QRCode size={200} value={`${window.h5Host}/h5/app-download-branch.html`} />
                            <p className="padding-top-5">请用微信扫码下载</p>
                          </div>
                        )}
                        trigger="click"
                        overlayClassName="white"
                      >
                        <span style={{ color: '#4d94ff', cursor: 'pointer' }}>新版车服APP</span>
                      </Popover>
                      扫一扫登陆
                    </span>
                  </div>
                  {isLoginExpire ? (
                    <div className="refresh-login">
                      <img
                        style={{ width: 189, height: 185 }}
                        src={require('../../images/daotian/qrcode-expire.jpg')}
                      />

                      <div className="mask" onClick={() => this.handleRefresh()}>
                        <img
                          className="mt30"
                          style={{ width: 48, height: 48 }}
                          src={require('../../images/daotian/refresh.png')}
                        />
                        <div className="mt20">二维码已失效</div>
                      </div>
                    </div>
                  ) : (
                    <Spin spinning={!loginId} tip="加载中...">
                      {loginId ? (
                        <QRCode
                          size={185}
                          value={JSON.stringify({
                            type: 'login',
                            data: { login_id: loginId },
                          })}
                        />
                      ) : (
                        <div style={{ width: 185, height: 185 }}>
                          <img
                            alt=""
                            style={{ width: 189, height: 185 }}
                            src={require('../../images/daotian/qrcode-expire.jpg')}
                          />
                        </div>
                      )}
                    </Spin>
                  )}

                  <Row>
                    <Col span={24} />
                  </Row>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <p>
              Copyright © 2012-{new Date().getFullYear()}北京稻成科技有限公司. All Rights Reserved
              <a className="ml10 text-default" href="http://www.beian.miit.gov.cn" target="_blank">
                京ICP备15040810号
              </a>
            </p>
          </footer>
        </div>

        <DownloadGoogle />
      </div>
    );
  }
}
export default Login;
