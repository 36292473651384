import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AuthUtil from '../utils/AuthUtil';

const { branchName } = AuthUtil.getLoginUserInfo();

const authorization = (pathAuth, path) => {
  const userPermissions = JSON.parse(AuthUtil.getUserPermissions());
  const hasPermission = userPermissions.find(permission => permission.item_path === pathAuth);

  if (hasPermission) {
    try {
      _czc.push(['_trackEvent', branchName, path]);
    } catch (e) {
      console.log('catch=>', e);
    }
  }
  return !!hasPermission;
};

const assemblePath = (path) => {
  if (path) {
    if (path.indexOf(':') > -1) {
      path = path.substring(0, path.indexOf(':'));
    }

    if (path.startsWith('/')) {
      path = path.replace('/', '');
    }

    if (path.endsWith('/')) {
      path = path.substring(0, path.length - 1);
    }
    return path;
  }
  return '/home';
};

const processAuth = (path) => {
  const pathAuth = assemblePath(path);

  if (!pathAuth) {
    try {
      _czc.push(['_trackEvent', branchName, path]);
    } catch (e) {
      console.log('catch=>', e);
    }
    return true;
  }

  if (Number(['home', 'permission-403', 'permission-404'].indexOf(pathAuth)) > -1) {
    try {
      _czc.push(['_trackEvent', branchName, path]);
    } catch (e) {
      console.log('catch=>', e);
    }
    return true;
  }

  return authorization(pathAuth, path);
};

const auth = (path) => {
  // 除门店店总经理，检查路由权限
  const user = AuthUtil.getLoginUserInfo();
  if (user.uid !== user.branchAdminUserId && !user.isAdminBranchUser) {
    return processAuth(path);
  }
  try {
    _czc.push(['_trackEvent', branchName, path]);
  } catch (e) {
    console.log('catch=>', e);
  }
  return true;
};

const PrivateRouteWithAuth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      // eslint-disable-next-line no-nested-ternary
      AuthUtil.isLogin() ? auth(props.match.path) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/permission-403', state: { from: props.location } }} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    )}
  />
);

export default PrivateRouteWithAuth;
