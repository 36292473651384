import {
  DashboardOutlined,
  PropertySafetyOutlined,
  ToolOutlined,
  BulbOutlined,
  MedicineBoxOutlined,
  ShopOutlined,
  UserOutlined,
  CarOutlined,
  SettingOutlined,
  InboxOutlined, AppstoreOutlined,
  AreaChartOutlined,
} from '@ant-design/icons';

const menuChengshi = [
  {
    key: 'business_manage',
    icon: AreaChartOutlined,
    name: '数据统计',
    subMenu: [
      {
        name: '数据分析',
        path: '/dashboard/analysis',
      }, {
        name: '业务概况',
        path: '/dashboard/business',
      },
    ],
  }, {
    key: 'visit_manage',
    icon: DashboardOutlined,
    name: '拜访管理',
    subMenu: [
      {
        name: '拜访记录',
        path: '/dashboard/visit',
      }, {
        name: '数据收集',
        path: '/dashboard/journal',
      }, {
        name: '拜访计划',
        path: '/dashboard/visit/plan',
      }, {
        name: '拜访轨迹',
        path: '/dashboard/visit/track',
      }, {
        name: '拜访轨迹-鹰眼',
        path: '/dashboard/visit/track-yingyan',
      },
    ],
  }, {
    key: 'finance',
    icon: PropertySafetyOutlined,
    name: '财务',
    subMenu: [
      /* {
        name: '收款管理',
        path: '/finance/pay',
      }, {
        name: '到账记录',
        path: '/finance/transfer',
      }, */{
        name: '收支管理',
        path: '/finance/expense',
      }, {
        name: '应收账款',
        path: '/finance/on-account',
      }, {
        name: '活动资金',
        path: '/finance/wallet',
      },
    ],
  }, {
    key: 'part_sell',
    icon: ToolOutlined,
    name: '配件销售',
    subMenu: [
      {
        name: '订单管理',
        path: '/parts-sale/order',
      }, {
        name: '订单削减',
        path: '/parts-sale/order-cut',
      }, {
        name: '退货/拒收',
        path: '/parts-sale/order-return',
      }, {
        name: '商品管理',
        path: '/parts-sale/goods-item',
      }, {
        name: '商品类别',
        path: '/parts-sale/goods',
      }, {
        name: '品牌意向',
        path: '/dashboard/brand-intention',
      }, {
        name: '配件管理',
        path: '/parts-sale/parts',
      }, {
        name: '品牌管理',
        path: '/parts-sale/brand-config',
      },
    ],
  }, {
    key: 'inventory',
    icon: InboxOutlined,
    name: '库存管理',
    subMenu: [
      {
        name: '仓库管理',
        path: '/inventory/warehouse',
      }, /* , {
        name: '仓库盘点',
        path: '/inventory/stocktaking',
      }, {
        name: '损溢单',
        path: '/inventory/income-statement',
      } */ {
        name: '采购管理',
        path: '/inventory/purchase',
      }, {
        name: '调拨管理',
        path: '/inventory/warehouse-move',
      }, {
        name: '入库管理',
        path: '/inventory/warehouse-input',
      }, {
        name: '出库管理',
        path: '/inventory/warehouse-output',
      },
    ],
  }, {
    key: 'shuidao_purchase',
    icon: AppstoreOutlined,
    name: '水稻采购',
    subMenu: [
      {
        name: '商品管理',
        path: '/shuidao-purchase/goods',
      }, {
        name: '订单管理',
        path: '/shuidao-purchase/order',
      },
    ],
  }, {
    key: 'marketing',
    icon: BulbOutlined,
    name: '营销',
    subMenu: [
      {
        name: '优惠活动',
        path: '/parts-sale/activity',
      }, {
        name: '短信管理',
        path: '/setting/sms',
      }, {
        name: '品牌活动',
        path: '/repair/activity-brand-config',
      }, {
        name: '推送记录',
        path: '/repair/push',
      },
    ],
  }, {
    key: 'maintain',
    icon: MedicineBoxOutlined,
    name: '保养业务',
    subMenu: [
      {
        name: '套餐卡管理',
        path: '/maintain/coupon-card',
      }, {
        name: '顾客线索',
        path: '/maintain/customer-clue',
      }, {
        name: '购买记录',
        path: '/maintain/buy-logs-log',
      }, {
        name: '核销记录',
        path: '/maintain/consume',
      },
    ],
  }, {
    key: 'store',
    icon: ShopOutlined,
    name: '门店',
    subMenu: [
      {
        name: '汽修管理',
        path: '/store-shop',
      }, {
        name: '汽贸管理',
        path: '/store-trade',
      }, {
        name: '门店地图',
        path: '/store-shop-map',
      }, {
        name: '线索管理',
        path: '/store-clue',
      },
    ],
  }, {
    key: 'personnel',
    icon: UserOutlined,
    name: '人事',
    subMenu: [
      {
        name: '员工管理',
        path: '/personnel',
      }, {
        name: '抖音账号',
        path: '/douyin-customer',
      }, {
        name: '抖音视频',
        path: '/douyin-video',
      },
    ],
  }, {
    key: 'models',
    icon: CarOutlined,
    name: '车队',
    subMenu: [
      {
        name: '车队管理',
        path: '/vehicle/list',
      },
    ],
  }, {
    key: 'youche',
    icon: CarOutlined,
    name: '水稻优车',
    subMenu: [
      {
        name: '产品',
        path: 'group',
        items: [
          {
            name: '固定首尾付',
            path: '/chengshi/product/vehicle',
          }, {
            name: '贷款分期',
            path: '/chengshi/product/finance',
          },
        ],
      }, {
        name: '方案',
        path: 'group',
        items: [
          {
            name: '固定首尾付',
            path: '/chengshi/plan/vehicle',
          }, {
            name: '贷款分期',
            path: '/chengshi/plan/finance',
          },
        ],
      },
    ],
  }, {
    key: 'setting',
    icon: SettingOutlined,
    name: '设置',
    subMenu: [
      {
        name: '公司信息',
        path: '/setting/company',
      },
    ],
  },
];

export default menuChengshi;
